<template>
  <div>
    <v-btn
      v-if="status == 'Pending'"
      :disabled="status != 'Pending'"
      @click="$emit('click')"
      @mouseenter="handleHover(true)"
      @mouseleave="handleHover(false)"
      >{{ displayText }}</v-btn
    >
    <span v-else
      ><v-icon v-if="status == 'Cancelled'">mdi-cancel</v-icon>
      <v-icon v-else-if="status == 'Claimed'">mdi-gift-open</v-icon>
      <v-icon v-else-if="status == 'Expired'">mdi-alarm-off</v-icon>
      {{ displayText }}</span
    >
  </div>
</template>
<script>
export default {
  props: ["status"],
  components: {},
  data() {
    return {
      displayText: this.status,
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleHover(isHover) {
      if (isHover) this.displayText = this.$t("app.actions.cancel");
      else this.displayText = this.status;
    },
  },
};
</script>