<template>
  <v-row>
    <v-col cols="12" class="mt-10">
      <v-card tile elevation="0">
        <v-card-title>
          {{
            $t("page.profileInvites.title", {
              nbOfInvites: nbOfInvites == -1 ? "∞" : nbOfInvites,
            })
          }}
        </v-card-title>
        <v-card-text v-if="nbOfInvites != 0">
          <v-card tile>
            <v-card-text>
              <v-form v-model="friendInviteForm">
                <v-text-field
                  v-model="friendEmail"
                  ref="friendEmailField"
                  :label="
                    $t('page.profileInvites.newFriendForm.friendEmailTitle')
                  "
                  type="email"
                  placeholder="myfriend@somedomain.com"
                  clearable
                  @click:clear="clearFriendEmail($event)"
                  @blur="clearFriendEmail($event)"
                  :rules="friendEmailRules"
                ></v-text-field>
                <v-btn
                  tile
                  class="success"
                  @click="sendFriendInvitation()"
                  :disabled="!friendInviteForm"
                  >{{ $t("page.profileInvites.newFriendForm.send") }}</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card tile>
        <v-card-subtitle class="subtitle-1">Sent invites</v-card-subtitle>
        <v-card-text>
          <v-simple-table fixed-header dense>
            <thead>
              <tr>
                <th class="text-left" width="20%">
                  {{ $t("page.profileInvites.sentInvites.emailHeader") }}
                </th>
                <th class="text-left">
                  {{ $t("page.profileInvites.sentInvites.linkHeader") }}
                </th>
                <th class="text-left" width="20%">
                  {{ $t("page.profileInvites.sentInvites.statusHeader") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="invite in invites" :key="invite.email">
                <td>{{ invite.inviteeEmail }}</td>
                <td>
                  <v-text-field
                    v-model="invite.link"
                    readonly
                    filled
                    class="my-0"
                    :hide-details="true"
                    :class="{ striked: invite.status !== 'Pending' }"
                  >
                    <template
                      v-slot:prepend-inner
                      v-if="invite.status == 'Pending'"
                    >
                      <v-btn
                        :rounded="false"
                        small
                        class="mr-2 primary"
                        @click="copyToClipboard(invite.link)"
                      >
                        <v-icon> mdi-content-copy </v-icon>
                      </v-btn>
                    </template></v-text-field
                  >
                </td>
                <td>
                  <status-button
                    :status="invite.status"
                    @click="cancelInvitation(invite.token)"
                  ></status-button>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import copyToClipboard from "/mixins/copy-to-clipboard.js";
import StatusButton from "../../components/Profile/StatusButton.vue";
export default {
  mixins: [copyToClipboard],
  name: "Profile.Invitation",
  components: { StatusButton },
  data() {
    return {
      invitesData: [],
      friendInviteForm: null,
      friendEmail: null,
      friendEmailRules: [
        (val) => {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (
            re.test(String(val).toLowerCase()) ||
            this.$t("page.profileInvites.newFriendForm.friendEmailInvalid")
          );
        },
        (val) => {
          var userEmail = this.$store.getters["profile/profile"].email;
          return (
            userEmail != val ||
            this.$t("page.profileInvites.newFriendForm.friendEmailSameAsUser")
          );
        },
      ],
    };
  },
  computed: {
    nbOfInvites() {
      var nbInvites = this.$store.getters["profile/profile"].invitesLeft;

      return nbInvites;
    },
    invites() {
      var link =
        process.env.NODE_ENV == "production"
          ? "http://cloudshelf-app.alexisdiamond.net"
          : "http://localhost:8080";
      return this.invitesData.map((x) => ({
        ...x,
        link: `${link}/claim-account/${x.token}`,
      }));
    },
  },
  methods: {
    sendFriendInvitation() {
      this.$store.dispatch("user/invite", { friendEmail: this.friendEmail });
      this.clearFriendEmail();
      this.loadInvitationList();
    },
    clearFriendEmail(val) {
      if (val instanceof FocusEvent) if (val.target.value) return;
      this.$refs.friendEmailField.reset();
    },
    loadInvitationList() {
      this.$store.dispatch("user/fetchMemberInvitationsList").then((x) => {
        this.invitesData = x;
      });
    },
    cancelInvitation(token) {
      this.$store.dispatch("user/cancelInvitation", token);
    },
  },
  created() {
    this.loadInvitationList();
  },
};
</script>

<style scoped>
.striked {
  text-decoration: line-through;
}
</style>