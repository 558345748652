import store from '/store/index'
import * as types from '/store/mutation-types'
import i18n from '../i18n'
export default {
    methods: {
        copyToClipboard: str => {
            const el = document.createElement('textarea');
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            store.commit('notification/' + types.SET_SNACKBAR, { message: i18n.t('app.actions.copiedToClipboard') })
        }
    }
};